var POST_LOGIN_ACTION_ID_URL_PARAM = 'post_login_action_id';

/**
 * This service has methods for creating a "post login action" on the server side.
 *
 * A typical "post login action" starts when the user is logged out and
 * continues after the user has been redirected to the login page, and then
 * redirected back to the original page after logging in.
 *
 * An example flow would go through these steps:
 *
 *  1. The user tries to add a variation to a web project.
 *  2. Before adding the variation, the authentication service will check to see
 *     if the user is logged in.
 *  3. If the user isn't logged in, the authentication service will use
 *     PostLoginActionCreatorService to create a post login action on the server
 *     side that stores the type of action the user was trying to execute, along
 *     with any data needed to continue the action.
 *  4. The authentication service will append the new post action's id to the url and
 *     then redirect the user to the login page.
 *  5. The user will log in and get redirected back to the original page url
 *     that also contains the post login action id.
 *  6. The app will see the post login action id in the url and use
 *     PostLoginActionProcessorService to request the data it needs to continue the
 *     action.
 *
 * @ngInject
 */
function PostLoginActionCreatorService($http) {
  return {
    createPostLoginAction: createPostLoginAction,
    getPostLoginActionParamsForUrl: getPostLoginActionParamsForUrl
  };

  /**
   * Create a post login action on the server side using the action name and the
   * data. Once the action has been created, resolve the promise with the id of
   * the action.
   *
   * @param {String} actionName
   * @param {Object} data
   * @return {Promise}
   */
  function createPostLoginAction(actionName, data) {
    return $http.post('/post_login_actions', {
      name: actionName,
      data: data
    }).then(function(response) {
      return response.data.postLoginActionId;
    });
  }

  /**
   * Returns the url params that are necessary for a post login action redirect.
   *
   * @param {String} postLoginActionId
   * @return {Object}
   */
  function getPostLoginActionParamsForUrl(postLoginActionId) {
    var params = {};
    params[POST_LOGIN_ACTION_ID_URL_PARAM] = postLoginActionId;
    return params;
  }
}

module.exports = PostLoginActionCreatorService;

