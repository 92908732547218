/**
 * Utilities sending messages to Newrelic.
 */

let _pendingRequests = {};

/**
  * Report a PageAction event to NewRelic immediately.
  *
  * @param {String} name
  * @param {} attributes
  */
export function addPageAction(name, attributes) {
  // The Newrelic API is loaded through an external JS file, so make sure the
  // API present before continuing.
  if (typeof window.newrelic == 'undefined' || !window.newrelic) {
    return;
  }

  window.newrelic.addPageAction(name, attributes);
}

/**
  * Report a PageAction event to NewRelic with a de-duplication delay. This
  * waits for the specified number of milliseconds to pass before dispatching
  * the event. If an event with the same name comes in before that, the
  * older event is canceled.
  *
  * @param {String} name
  * @param {} attributes
  * @param {Number} timeoutMs
  */
export function addPageActionWithDelay(name, attributes, timeoutMs) {
  // If there was already a request made for this specific action name, cancel
  // it and make a new request.
  if (_pendingRequests[name]) {
    clearTimeout(_pendingRequests[name]);
  }

  // Wait before sending a newrelic request, so we can cancel the request if
  // another comes in before the timeout has passed.
  _pendingRequests[name] = setTimeout(function() {
    addPageAction(name, attributes);
    delete _pendingRequests[name];
  }, timeoutMs);
}

/**
 * Add listeners for analytics events from adobe-fonts-site and send them to NewRelic
 */
export function init() {
  window.addEventListener('af-analytics', function(event) {
    const actionName = ['af-analytics', event.detail.componentName, event.detail.eventName].join('.');
    const {
      componentName,
      eventName,
      ...attributes
    } = event.detail;

    addPageAction(actionName, attributes);
  });
}

/**
  * Adds a custom attribute to the PageView event via the Browser agent
  *
  * @param {String} name
  * @param {String} value
  */
export function setCustomAttribute(name, value) {
  // The Newrelic API is loaded through an external JS file, so make sure the
  // API present before continuing.
  if (!window.newrelic) {
    return;
  }

  window.newrelic.setCustomAttribute(name, value);
}

/**
 * Stringify a click with page name, custom labeling (optional), and event type
 *
 * @param {String} page
 * @param {Event} event
 * @param {String} label
 * @returns String
 */
export function getNewRelicName(page, event, label = null) {
  return label ? ['typekit', page, label, event.type].join('.') : ['typekit', page, event.type].join('.');
}

/**
 * Get the referrer URL that led the user to the current page
 *
 * @returns String
 */
export function getReferrerHeader() {
  return window.document.referrer;
}
